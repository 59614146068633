import React from 'react'

// Components
import Layout from '../components/Layout'
import {GalleryMilkyway}  from '../components/Gallery'

const MilkywayPage = ({}) => (
  <>
    <Layout />
    <GalleryMilkyway />
  </>
)

export default MilkywayPage
